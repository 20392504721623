import React, { useState } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import ImageBackground from 'components/shared/ImageBackground'

// Images
import orangeArrow from 'img/orange_arrow.svg'
import Close from 'img/x.svg'
import 'styles/VideoBox.scss'

export default function VideoBox({ title, desc, placeholder, video, openVideo, setOpen }) {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  return (
    <>
      <Back src={placeholder} className="video">
        <Overlay
          className="d-flex flex-column "
          onClick={() => setIsOpen(true)}
        >
          <Card className="row position-absolute w-80 m-0 py-2 color-background-main">
            <div className="col-10">
              <p className="m-0 font-size-sm font-weight-xl color-text-light">
                {title}
              </p>
              <p className="m-0 font-size-sm font-weight-m color-text-light">
                {desc}
              </p>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <img alt="" src={orangeArrow} />
            </div>
          </Card>
        </Overlay>
      </Back>
      {isOpen && (
        <Modal onClick={() => setIsOpen(false)}>
          <div onClick={(e) => e.stopPropagation()} className="position-relative">
            <CloseIcon onClick={() => setIsOpen(false)}>
              <img src={Close} alt="close" />
            </CloseIcon>
            <iframe
              src={video}
              width="640"
              height="360"
              frameBorder="0"
              title="Kom bij ons werken"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </Modal>
      )}
    </>
  )
}

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div {
    background-color: rgba(0,0,0,.5);
    border-radius: 15px;
    padding: 15px;
  }
`

const CloseIcon = styled.div`
  background-color: #FFF;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 15px;
  cursor: pointer;
`

const Back = styled(ImageBackground)`
  width: 100%;
  min-height: 250px;
  display: flex;
`
const Overlay = styled.div`
  width: 100%;
  cursor: pointer;
`
const Card = styled.div`
    bottom: -25px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
`