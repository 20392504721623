/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import Image from 'gatsby-image'

// Image
import playCircle from 'img/play-circle.svg'
import Close from 'img/x.svg'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const PlayIcon = styled.div`
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255,255,255,.3);
  box-shadow: 0 0 10px rgba(255,255,255,.3);
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  & img {
    height: 100px;
    width: 100px;
  }

  &:hover {
    width: 200px;
    height: 200px;
    backdrop-filter: blur(10px);
  }
`

const ImageWrapper = styled.div`
  cursor: pointer;
`

const StyledNewVideoModal = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  text-align: center;

  & p {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 40px;
  }

  @media screen and (max-width: 768px) {
    & p {
      font-size: 20px;
      line-height: 20px;
    }

    & iframe {
      width: 520px;
      height: 300px;
    }
  }

  @media screen and (max-width: 576px) {
    & iframe {
      width: 350px;
      height: 200px;
    }
  }

  @media screen and (max-width: 350px) {
    & iframe {
      width: 280px;
      height: 160px;
    }
  }
`

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div {
    background-color: rgba(0,0,0,.5);
    border-radius: 15px;
    padding: 15px;
  }
`

const CloseIcon = styled.div`
  background-color: #FFF;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 15px;
  cursor: pointer;
`

const NewVideoModal = ({ thumbnail, url, description }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <StyledNewVideoModal className="py-5">
      {isOpen && (
        <Modal onClick={() => setOpen(false)}>
          <div onClick={(e) => e.stopPropagation()} className="position-relative">
            <CloseIcon onClick={() => setOpen(false)}>
              <img src={Close} alt="close" />
            </CloseIcon>
            <iframe
              src={url}
              width="640"
              height="360"
              frameBorder="0"
              title="Kom bij ons werken"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </Modal>
      )}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 pb-4">
            {parse(description)}
          </div>
          <div className="col-md-10">
            <ImageWrapper onClick={() => setOpen(true)} className="position-relative">
              <PlayIcon>
                <img src={playCircle} alt="" />
              </PlayIcon>
              <Image fluid={thumbnail} alt="" />
            </ImageWrapper>
          </div>
        </div>
      </div>
    </StyledNewVideoModal>
  )
}

export default NewVideoModal
