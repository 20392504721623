/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/Projecten.scss'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import BrandSlider from 'components/Brands/BrandSlider'
import ImageBox from 'components/ImageBox'
import VideoBox from 'components/VideoBox'
import OpenVideo from 'components/OpenVideo'
import NewVideoModal from '../components/VideoModal/NewVideoModal'

const Projecten = ({location}) => {
  const {
    ervaringPagina: {
      acf: {
        page_content: {
          banner: ervaringBanner,
          second_banner: ervaringBanner2,
          image: {
            localFile: {
              childImageSharp: { fluid: ervaringImage },
            },
          },
        },
      },
      yoast_meta: yoast,
    },
    project: { edges: projectenList },
  } = useStaticQuery(graphql`
    {
      ervaringPagina: wordpressPage(wordpress_id: { eq: 613 }) {
        acf {
          page_content {
            banner {
              button_text
              text
            }
            second_banner {
              button_text
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
      project: allWordpressWpProjecten(sort: {fields: date, order: DESC}) {
        edges {
          node {
            acf {
              information {
                title
                location
                featured_image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              media {
                gallery {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                        openGraph: fluid(maxWidth: 850, toFormat: JPG) {
                          src
                        }
                      }
                    }
                  }
                }
                video_url
              }
            }
            path
            slug
          }
        }
      }
    }
  `)

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  const [chunkSize, setChunkSize] = useState(6)

  const updateChunk = () => {
    setChunkSize(chunkSize + 6)
  }

  const [openVideo, setOpenVideo] = useState(false)
  const [openHuizenVideo, setHuizenVideo] = useState(false)
  const [openSoestVideo, setSoestVideo] = useState(false)
  const [openRolluikenVideo, setRolluikenVideo] = useState(false)
  const [openGarageVideo, setGarageVideo] = useState(false)
  const [openHovenVideo, setHovenVideo] = useState(false)

  useEffect(() => {
    if(location.hash === '#eigen-huis') {
      setOpenVideo(true)
    } else if(location.hash === '#familie-huizen') {
      setHuizenVideo(true)
    } else if(location.hash === '#familie-soest') {
      setSoestVideo(true)
    } else if(location.hash === '#rolluiken-screens') {
      setRolluikenVideo(true)
    } else if(location.hash === '#garagedeuren') {
      setGarageVideo(true)
    } else if(location.hash === '#famhoven') {
      setHovenVideo(true)
    }

  }, [])

  return (
    <Layout>
      {openVideo && <OpenVideo video="https://player.vimeo.com/video/662889748" />}
      {openHuizenVideo && <OpenVideo video="https://player.vimeo.com/video/591484839" />}
      {openSoestVideo && <OpenVideo video="https://player.vimeo.com/video/695661190?h=51bf71fc14" />}
      {openRolluikenVideo && <OpenVideo video="https://player.vimeo.com/video/705680721?h=17cba5121d" />}
      {openGarageVideo && <OpenVideo video="https://player.vimeo.com/video/705680795?h=1b98016c5a" />}
      {openHovenVideo && <OpenVideo video="https://player.vimeo.com/video/705709628?h=e6ade2fbee" />}
      <SEO yoast={{ meta: yoast }} />
      <div className="projecten">
        <BackgroundImage
          loading="eager"
          className="smaller-background-image"
          alt=""
          fluid={ervaringImage}
        />
        <BannerCustom
          to="/showroomafspraak-maken"
          className="color-background-secondary"
          bannerText={ervaringBanner.text}
          bannerButtonText={ervaringBanner.button_text}
        />
        <div className="container py-5">
          <h2 className="m-0 text-center font-size-xl font-weight-xl color-text-main">
            Onze projecten
          </h2>
          <p className="m-0 text-center font-size-sm font-weight-xl color-text-contrast">
          Hier vindt u een greep uit onze succesvolle projecten, klik op de foto’s voor meer inspiratie!
          </p>
          <div className="row pt-5">
            {chunk(projectenList, chunkSize)[0].map((project, index) => {

              return (
                <Project
                  key={index}
                  className="col-12 col-lg-4 mb-5"
                  project={project.node}
                />
              )
            })}
          </div>
          <div className="d-flex justify-content-center">
            {chunk(projectenList, chunkSize).length > 1 && (
              <div
                tabIndex="0"
                role="button"
                className="color-background-contrast font-size-xm font-weight-xl color-text-light px-4 py-2 text-uppercase"
                onKeyDown={() => updateChunk()}
                onClick={() => updateChunk()}
              >
                Laad meer
              </div>
            )}
          </div>
        </div>
        <BannerCustom
          to="/ervaringen/"
          className="color-background-main"
          bannerText={ervaringBanner2.text}
          bannerButtonText={ervaringBanner2.button_text}
        />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default Projecten

const Project = ({ project, className }) => {

  return (
    <div className={`project ${className ? `${className}` : ``}`}>
        <div className="project-image-container">
             {project.acf.media.gallery ? (
              <ImageBox
                title={project.acf.information.title}
                desc={project.acf.information.location}
                placeholder={
                  project.acf.information.featured_image
                }
                images={project.acf.media.gallery.map(
                  (d) => d.image.localFile.childImageSharp.openGraph.src
                )}
              />
            ) : (
              <VideoBox
                title={project.acf.information.title}
                desc={project.acf.information.location}
                placeholder={
                  project.acf.information.featured_image
                }
                video={project.acf.media.video_url}
              />
          )}
        </div>
    </div>
  )
}
