import React, { useState } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import ImageBackground from 'components/shared/ImageBackground'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

// Images
import orangeArrow from 'img/orange_arrow.svg'

export default function ImageBox({ title, desc, placeholder, images }) {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  return (
    <>
      <Back src={placeholder} className="video"> 
        <Overlay
          className="d-flex flex-column "
          onClick={() => setIsOpen(true)}
        >
          <Card className="row position-absolute w-80 m-0 py-2 color-background-main">
            <div className="col-10">
              <p className="m-0 font-size-sm font-weight-xl color-text-light">
                {title}
              </p>
              <p className="m-0 font-size-sm font-weight-m color-text-light">
                {desc}
              </p>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <img alt="" src={orangeArrow} />
            </div>
          </Card>
        </Overlay>
      </Back>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  )
}

const Back = styled(ImageBackground)`
  width: 100%;
  min-height: 250px;
  display: flex;
`
const Overlay = styled.div`
  width: 100%;
  cursor: pointer;
`
const Card = styled.div`
    bottom: -25px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
`